import React from "react";
import FadeUpDown from "../Animation/FadeUpDown";

const Landing = () => {
  return (
    <section id="">
      <div className="grid lg:grid-cols-2">
        <div className="block lg:hidden">
          <FadeUpDown>
            <img src="/assets/home/home.png" alt="home" />
          </FadeUpDown>
        </div>
        <div className="grid place-content-center lg:pl-[10%] px-[10%]">
          {/* <FadeUpDown> */}
            <div className="pt-20">
              <FadeUpDown>

              <h1 className="md:text-6xl text-4xl text-primary font-bold font-gilroy leading-none drop-shadow-3xl grid">
                Learn OET from <span>the Professionals.</span>{" "}
              </h1>
              <p className="text-xl font-bold lg:w-[33ch] leading-relaxed font-gil py-5 text-grey">
                Increase your chance of success by getting Trained by
                professional with atleast 7+ years of Teaching experience.
              </p>
              </FadeUpDown>
            </div>
            <div className="py-5">
              <FadeUpDown>
              <a href="tel:+91 7736629777">
              <button className="text-white bg-secondary px-5 py-2 rounded-3xl text-md font-gil font-semibold border-2 border-seondary hover:text-secondary hover:bg-white drop-shadow-xl">
                Book a Counselling Call
              </button>
              </a>
              </FadeUpDown>
            </div>
            <FadeUpDown>

            <div className="flex pt-20 gap-4 items-center h-3/4">
              <div className="">
                <img
                  className=""
                  src="/assets/home/students.png"
                  alt="students"
                  />
              </div>
              <div className="">
                <h3 className="font-gil text-base font-semibold w-[20ch]">
                  Trusted by 10,000+ students from all over India
                </h3>
              </div>
            </div>
                  </FadeUpDown>
          {/* </FadeUpDown> */}
        </div>
        <div className="lg:block hidden">
          <FadeUpDown>
            <img src="/assets/home/home.png" alt="home" />
          </FadeUpDown>
        </div>
      </div>
    </section>
  );
};

export default Landing;

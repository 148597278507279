import React from 'react'

const FeedCard = ({img, namee, country, text}) => {
  return (
    <div className='bg-white border-2 border-grey/[0.20] px-10 py-5 rounded-xl my-5 w-full'>
        <div className='flex items-center gap-3 py-3'>
            <div>
                <img className='h-3/4 w-3/4' src={img} alt='feedback' />
            </div>
            <div className=''>
                <h2 className='font-gill text-xl font-semibold'>{namee}</h2>
                <h3 className='text-lg font-gill text-gray'>{country}</h3>
            </div>
        </div>
        <div className='py-3'>
            <p className='text-gray text-base font-gill '>{text}</p>
        </div>
    </div>
  )
}

export default FeedCard
import React, { useState } from "react";
import { IoCall } from "react-icons/io5";
import { GoMail } from "react-icons/go";
import { FaLocationDot } from "react-icons/fa6";
import FadeUpDown from "../Animation/FadeUpDown";
import axios from "axios";

const CTA = () => {
  const [user, setUser] = useState({
    fname: "",
    lname: "",
    phone: "",
    email: "",
    message: "",
  });

  let name, value;
  const getUserData = (event) => {
    name = event.target.name;
    value = event.target.value;

    setUser({ ...user, [name]: value });
  };

  const postData = async (e) => {
    e.preventDefault();

    const { fname, lname, phone, email, message } = user;

    if (fname && lname && phone && email && message) {
      const res = await fetch(
        "https://language-hub-d0709-default-rtdb.firebaseio.com/form-responses.json",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fname,
            lname,
            phone,
            email,
            message,
          }),
        }
      );

      axios.post('https://sheet.best/api/sheets/2073497f-0f2c-4e63-a8c8-99f23420814d',user);

      if (res) {
        setUser({
          fname: "",
          lname: "",
          phone: "",
          email: "",
          message: "",
        });

        alert("Message Sent!");
      }
    } else {
      alert("Please fill all the fields!");
    }
  };

  return (
    <section className="md:px-[20%] md:py-20 py-10 px-10" id="contact-us">
      <div className="lg:flex h-full justify-between">
        <div className="grid place-content-between">
          <div>
            <FadeUpDown xOffset={-15} delay={0.4}>
              <h1 className="text-4xl font-gill font-semibold pb-3 text-primary">
                Any Questions ?
              </h1>
            </FadeUpDown>
            <FadeUpDown xOffset={-15} delay={0.4}>
              <h1 className="text-4xl font-gill font-semibold text-primary">
                Chat with us
              </h1>
            </FadeUpDown>
          </div>
          <div className="lg:block md:flex block items-center justify-between gap-20 py-5">
            <FadeUpDown xOffset={-15} delay={0.6}>
              <a href="tel:+91 7736629777">
              <h3 className="flex items-center gap-3 text-gray font-medium font-gill lg:pb-2 md:pb-0 pb-2">
                <IoCall className="w-5 h-5" /> <span>+91 77366 29777</span>
              </h3>
              </a>
            </FadeUpDown>
            <FadeUpDown xOffset={-15} delay={0.6}>
              <a href="mailto:info@languageexperts.com">
                <h3 className="flex items-center gap-3 text-gray font-medium font-gill">
                  <GoMail className="w-5 h-5" />{" "}
                  <span>info@languageexperts.in</span>
                </h3>
              </a>
            </FadeUpDown>
          </div>
        </div>
        <div className="w-[0.1px] bg-grey"></div>
        <div>
          <form method="POST">
            <FadeUpDown xOffset={15} delay={0.4}>
              <div class="grid md:grid-cols-2 md:gap-6">
                <div class="relative z-0 mb-6 w-full group">
                  <input
                    type="text"
                    name="fname"
                    id="fname"
                    class="block py-2.5 px-0 w-full text-sm text-primary bg-white/[0.1] border-0 border-b-2 border-gray appearance-none focus:outline-none focus:ring-0 focus:border-primary peer font-semibold font-gill"
                    placeholder=" "
                    value={user.fname}
                    onChange={getUserData}
                    autoComplete="off"
                    required
                  />
                  <label
                    for="fname"
                    class="peer-focus:font-semibold font-semibold absolute text-sm text-primary duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 font-gill"
                  >
                    First Name
                  </label>
                </div>
                <div class="relative z-0 mb-6 w-full group">
                  <input
                    type="text"
                    name="lname"
                    id="lname"
                    class="block py-2.5 px-0 w-full text-sm text-primary bg-white/[0.1] border-0 border-b-2 border-gray appearance-none focus:outline-none focus:ring-0 focus:border-primary peer font-semibold font-gill"
                    placeholder=" "
                    value={user.lname}
                    onChange={getUserData}
                    autoComplete="off"
                    required
                  />
                  <label
                    for="lname"
                    class="peer-focus:font-semibold font-semibold absolute text-sm text-primary duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 font-gill"
                  >
                    Last Name
                  </label>
                </div>
              </div>
              <div class="grid md:grid-cols-2 md:gap-6">
                <div class="relative z-0 mb-6 w-full group">
                  <input
                    type="tel"
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    name="phone"
                    id="phone"
                    class="block py-2.5 px-0 w-full text-sm text-primary bg-white/[0.1] border-0 border-b-2 border-gray appearance-none focus:outline-none focus:ring-0 focus:border-primary peer font-semibold font-gill"
                    placeholder=" "
                    value={user.phone}
                    onChange={getUserData}
                    autoComplete="off"
                    required
                  />
                  <label
                    for="phone"
                    class="peer-focus:font-semibold font-semibold absolute text-sm text-primary duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 font-gill"
                  >
                    Phone Number
                  </label>
                </div>
                <div class="relative z-0 mb-6 w-full group">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    class="block py-2.5 px-0 w-full text-sm text-primary bg-white/[0.1] border-0 border-b-2 border-gray appearance-none focus:outline-none focus:ring-0 focus:border-primary peer font-semibold font-gill"
                    placeholder=" "
                    value={user.email}
                    onChange={getUserData}
                    autoComplete="off"
                    required
                  />
                  <label
                    for="email"
                    class="peer-focus:font-semibold font-semibold absolute text-sm text-primary duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 font-gill"
                  >
                    Your Email
                  </label>
                </div>
              </div>
              <div class="relative z-0 mb-6 w-full group">
                <textarea
                  rows={3}
                  type="text"
                  name="message"
                  id="message"
                  class="block py-2.5 px-0 w-full text-sm text-primary bg-white/[0.1] border-0 border-b-2 border-gray appearance-none focus:outline-none focus:ring-0 focus:border-primary peer font-semibold font-gill"
                  placeholder=" "
                  value={user.message}
                  onChange={getUserData}
                  autoComplete="off"
                  required
                />
                <label
                  for="message"
                  class="peer-focus:font-semibold font-semibold absolute text-sm text-primary duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 font-gill"
                >
                  Message
                </label>
              </div>
              <div className="pt-5 grid">
                <button
                  onClick={postData}
                  type="submit"
                  className="text-lg font-gill text-white shadow-xl bg-secondary px-7 py-1 rounded-lg font-medium border-2 border-secondary hover:text-secondary hover:bg-white"
                >
                  Submit
                </button>
              </div>
            </FadeUpDown>
          </form>
        </div>
      </div>
      <div className="lg:flex h-full justify-between py-20">
        <div className="grid place-content-between ">
          <div>
            <FadeUpDown xOffset={-15} delay={0.6}>
              <h1 className="text-4xl font-gill font-semibold text-primary">
                Or Visit Us
              </h1>
            </FadeUpDown>
          </div>
          <div>
            <FadeUpDown xOffset={-15} delay={0.6}>
              <h3 className="flex items-center gap-3 text-gray font-medium font-gill lg:pb-2 pb-10">
                <FaLocationDot className="w-5 h-5" />{" "}
                <span className="w-[25ch]">
                1c (s) Abad Oriental Gardens,
                Deshabamani Rd,
                Kochi 682026
                </span>
              </h3>
            </FadeUpDown>
          </div>
        </div>
        <div className="w-[0.1px] bg-grey"></div>
        <div>
          <FadeUpDown xOffset={15} delay={0.6}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3929.107631111352!2d76.29378287468568!3d10.007967690097828!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b080d0d12beee27%3A0xfd8bba70c6965205!2sAbad%20Oriental%20Gardens!5e0!3m2!1sen!2sin!4v1689764529524!5m2!1sen!2sin"
              className="lg:w-[350px] w-[300px]"
              height="300"
              title="map"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </FadeUpDown>
        </div>
      </div>
    </section>
  );
};

export default CTA;

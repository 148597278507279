import React from 'react'
import { useSwiper } from 'swiper/react'
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs'

const SwiperNavButtons = () => {
    const swiper = useSwiper();

  return (
    <div className='flex gap-4 w-50'>
        <button className='w-7 h-7 text-white rounded-full border-2 border-white p-1 hover:bg-white hover:text-blue hover:border-blue' onClick={() => swiper.slidePrev()}><BsArrowLeft className=' ' /></button>     
        <button onClick={() => swiper.slideNext()}><BsArrowRight className='w-7 h-7 text-white rounded-full border-2 border-white p-1 hover:bg-white hover:text-blue hover:border-blue' /></button>
    </div>
  )
}

export default SwiperNavButtons
import React from "react";
import OETCard from "../Cards/OETCard";
import FadeUpDown from "../Animation/FadeUpDown";
import Parallax from "../Animation/Parallax";

const OET = ({ items, texts }) => {
  return (
    <section id="why-choose-oet">
      <div className="lg:relative my-10 ">
        <div className="">
          <div className="py-10">
            <FadeUpDown yOffset={15} delay={0.6}>
            <h1 className="lg:text-5xl md:text-4xl text-3xl font-gilroy text-primary text-center">
              Why choose OET?
            </h1>
            </FadeUpDown>
          </div>
          <div className="lg:absolute w-full">
            <div className="lg:px-[22%] px-10">
              <FadeUpDown yOffset={15} delay={0.8}>
              <p className="text-xl text-grey font-gill text-center">
                OET or Occupational English Test is an exclusive test designed
                for assessing the language and communication skills of
                healthcare professionals who seek to register and practice in an
                English-speaking environment. It is accepted as proof of English
                proficiency for visas, study, registration and employment in
                over 11 countries across the globe.
              </p>
              </FadeUpDown>
            </div>
            <div className="grid lg:grid-cols-3 gap-14 lg:px-[17%] lg:py-20 py-14 md:px-[29%] px-[17%]">
              {items.map((item) => (
                <div>
                  <FadeUpDown yOffset={15} delay={0.6}>
                  <OETCard {...item} />
                  </FadeUpDown>
                </div>
              ))}
            </div>
            <div className="grid lg:grid-cols-2 gap-14 md:px-[29%] px-[17%]">
              {texts.map((item) => (
                <div>
                  <FadeUpDown yOffset={15} delay={0.8}>
                  <OETCard {...item} />
                  </FadeUpDown>
                </div>
              ))}
            </div>
          </div>
        </div>
        <img
          className="lg:block hidden px-10"
          src="/assets/home/oet.png"
          alt="why"
        />
      </div>

      <div className="bg-lightBlue lg:relative mt-[7%] pb-20 lg:pb-0">
        <div className="lg:absolute w-full">
          <div className="py-14">
            <FadeUpDown yOffset={15} delay={0.6}>
            <h1 className="lg:text-5xl md:text-4xl text-3xl font-gilroy text-primary text-center">
              OET Modules
            </h1>
            </FadeUpDown>
          </div>
          <div className="grid lg:grid-cols-4 gap-10 px-[8%] mt-5">
            <Parallax>

            <div className="bg-white shadow-xl  border-2 border-grey/[0.10] rounded-xl w-full p-5 h-fit">
              <img
                className="mx-auto"
                src="/assets/home/module1.png"
                alt=" oet icon"
                />
              <p className="font-gill text-lg font-gray text-center py-3 font-medium">
                The OET Listening sub-test is designed to assess a range of
                listening skills, such as identifying specific information,
                detail, gist, opinion or the speaker's purpose. These skills are
                assessed through note-completion tasks and multiple-choice
                questions. The listening texts are always associated with the
                healthcare domain.
              </p>
            </div>
                </Parallax>
                <Parallax>

            <div className="bg-white shadow-xl  border-2 border-grey/[0.10] rounded-xl w-full p-5 lg:mt-[25%]">
              <img
                className="mx-auto"
                src="/assets/home/module2.png"
                alt=" oet icon"
                />
              <p className="font-gill text-lg font-gray text-center py-3 font-medium">
              OET Writing tests the ability to produce a letter that is appropriate for the situation given the task. It is not necessary to repeat everything from the case notes or to give a chronological account. Be prepared to select and organise the information in a way that informs the reader appropriately and effectively.
              </p>
            </div>
              </Parallax>
              <Parallax>

            <div className="bg-white shadow-xl  border-2 border-grey/[0.10] rounded-xl w-full p-5 h-fit">
              <img
                className="mx-auto"
                src="/assets/home/module3.png"
                alt=" oet icon"
                />
              <p className="font-gill text-lg font-gray text-center py-3 font-medium">
              In each Speaking test, your identity and profession are checked by the interlocutor and there is a short warm-up conversation about your professional background. Then the role-plays are introduced one by one and you have three minutes to prepare for each. The role-plays take about five minutes each.
              </p>
            </div>
                </Parallax>
                <Parallax>

            <div className="bg-white shadow-xl  border-2 border-grey/[0.10] rounded-xl w-full p-5 lg:mt-[25%]">
              <img
                className="mx-auto"
                src="/assets/home/module4.png"
                alt=" oet icon"
                />
              <p className="font-gill text-lg font-gray text-center py-3 font-medium">
              The OET standard Reading Sub-test is designed to test your ability to read across different types of texts. There are numerous types of questions that can be asked like matching, sentence completion or short answer questions. Such reading texts are associated with the healthcare domain and can be cleared by students.
              </p>
            </div>
                </Parallax>
          </div>
        </div>
        <img
          className="lg:block hidden p-10"
          src="/assets/home/color.png"
          alt="why"
        />
      </div>

      <div className="md:py-14 relative lg:px-20 p-5 overflow-hidden ">
        <FadeUpDown xOffset={15} delay={0.6}>

        <h1 className="absolute md:text-center mx-[12%] md:mx-auto w-full md:text-5xl text-3xl font-gilroy text-primary ">
          Convivence Galore
        </h1>
        <img src="/assets/home/conv.png" alt="conv" />
        </FadeUpDown>
      </div>
    </section>
  );
};

export default OET;

OET.defaultProps = {
  items: [
    {
      img: "/assets/home/oet1.png",
      text: "Validates English Language Preficiency",
    },
    {
      img: "/assets/home/oet2.png",
      text: "Accepted in over 11 Countries Worldwide",
    },
    {
      img: "/assets/home/oet3.png",
      text: "Only Exam Validated by Healthcare Sector",
    },
  ],

  texts: [
    {
      img: "/assets/home/oet4.png",
      text: "Helps you Learn Real Patient Interaction Skills",
    },
    {
      img: "/assets/home/oet5.png",
      text: "Accepted for Study as well as Employment",
    },
  ],
};

import React, { useState } from "react";
import { BiSolidPhoneCall } from "react-icons/bi";
import { BsWhatsapp } from "react-icons/bs";
import { HashLink as Link } from "react-router-hash-link";
import { FaBars } from "react-icons/fa";
import { ImCross } from "react-icons/im";

const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <section className="absolute md:top-10 top-10 w-full z-50">
      <div
        className={
          nav
            ? "bg-primary/[0.9] top-0 h-screen z-20 fixed w-[75%] right-0 ease-in-out delay-1000 p-12"
            : "hidden"
        }
      >
        <div className="w-full grid place-content-end">

        <ImCross
          onClick={handleNav}
          className="text-white w-7 h-7 mt-1 md:mr-2"
          />
          </div>
        {Lists.map((List) => (
          <Link to={List.link} smooth>
            <h2
              onClick={handleNav}
              className="font-semibold text-white py-7 text-center border-b-[0.1px] hover:border-secondary border-white hover:text-secondary"
              key={List.id}
            >
              {List.title}{" "}
            </h2>
          </Link>
        ))}

        <div className="py-10">
          <Link to="#contact-us" smooth>
            <button onClick={handleNav} className="border-2 border-white w-full text-white rounded-lg py-2 px-3 font-semibold hover:bg-white hover:text-primary ">
              Contact Us
            </button>
          </Link>
        </div>
      </div>
      <div
        className=" lg:px-[15%] md:px-[5%] px-5 fixed
       w-full"
      >
        <div className="flex justify-around py-3 text-white rounded-lg items-center bg-dark">
          <div>
            <Link to="#" smooth>
              <img className="lg:w-48 w-40" src="/assets/logo.png" alt="logo" />
            </Link>
          </div>
          {Lists.map((List) => (
            <Link to={List.link} smooth>
              <h2
                className="font-bold lg:block hidden hover:text-secondary"
                key={List.id}
              >
                {List.title}{" "}
              </h2>
            </Link>
          ))}
          <div className="flex justify-between items-center gap-7">
            <Link to="#contact-us" smooth>
              <button className="border-2 border-white rounded-lg py-2 px-3 font-bold hover:bg-white hover:text-primary lg:block hidden">
                Contact Us
              </button>
            </Link>

            <div className="lg:hidden block">
              <FaBars
                onClick={handleNav}
                className={!nav ? "text-white h-7 w-7" : "hidden"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="fixed bottom-6 left-7">
        <div className=" w-full flex justify-between">
          <a href="tel:+91 7736629777">
            <div className="bg-call rounded-full ">
              <BiSolidPhoneCall className="w-12 h-12 p-2 text-white" />
            </div>
          </a>
        </div>
      </div>
      <div className="fixed  bottom-6 right-7 ">
        <div className=" w-full flex justify-between">
          <a href="https://api.whatsapp.com/send/?phone=917736629777&text=Hi%2C%0D%0A%0D%0AI+would+like+to+know+more+about+your+courses.+Please+contact+me.&type=phone_number&app_absent=0">
            <div className="bg-call rounded-full ">
              <BsWhatsapp className="w-12 h-12 p-2 text-white" />
            </div>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Navbar;

const Lists = [
  {
    id: 1,
    title: "Home",
    link: "#",
  },
  {
    id: 2,
    title: "About Us",
    link: "#about-us",
  },
  {
    id: 3,
    title: "Why Choose us",
    link: "#why-choose-us",
  },
  {
    id: 4,
    title: "Why Choose OET",
    link: "#why-choose-oet",
  },
];

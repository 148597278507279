import React from "react";
import { useState } from "react";
import { Accordion, AccordionBody } from "@material-tailwind/react";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";

const Question = ({ ques, ans, v }) => {
  const [arrow, setArrow] = useState(false);

  const [open, setOpen] = useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  const handleArrow = () => {
    setArrow(!arrow);
  };

  return (
    <section className="py-2  md:pl-0">
      <Accordion
        open={open === Number(v)}
        onClick={() => handleOpen(Number(v))}
      >
        <div
          className="text-grey flex justify-between border-b-[0.1px] border-gray"
          onClick={handleArrow}
        >
          <h1 className="text-xl font-medium text-primary font-gill">{ques}</h1>
          <div className="border-grey md:border-[1px] rounded-full opacity-50 -translate-y-2 scale-75">
            {!arrow ? (
              <HiChevronDown className="w-10 h-10 cursor-pointer pt-1" />
            ) : (
              <HiChevronUp className="w-10 h-10 cursor-pointer" />
            )}
          </div>
        </div>
        <AccordionBody>
          <p className="text-grey font-medium text-base py-2 font-gill">{ans}</p>
        </AccordionBody>
      </Accordion>
      <div className="mb-3"></div>
    </section>
  );
};

export default Question;

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper/modules";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css";
import FeedCard from "../Cards/FeedCard";
import SwiperNavButtons from "../SwiperNavButtons/SwiperNavButtons";
import FadeUpDown from "../Animation/FadeUpDown";

const Feedback = ({items}) => {
  return (
    <section className="bg-blue px-[15%] py-20">
      <div>
        <FadeUpDown yOffset={15} delay={0.4}>
        <h1 className="text-white text-3xl font-gill font-semibold w-[14ch]">
          What did the students say to us
        </h1>
        </FadeUpDown>

        <Swiper className=""
          modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={50}
          slidesPerView={1}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{ clickable: true }}
          breakpoints={{
            850: {
                slidesPerView: 2
            }
          }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
        >
          <div className="">
            <SwiperNavButtons />
          </div>
          {
            items.map((item) => (
                <SwiperSlide>
            <FeedCard {...item} />
          </SwiperSlide>
            ))
          }
          
        </Swiper>
      </div>
    </section>
  );
};

export default Feedback;


Feedback.defaultProps = {
    items: [
        {
            img: "/assets/home/feed6.png",
            namee: "Rahul Chaudhary",
            country: "UK Aspirant",
            text: "After spending just over two months at Language Hub, I was able to secure the UK OET score on my very first try. The additional support classes and training helped me improve in my weaker modules like Listening and Reading."
        },
        {
            img: "/assets/home/feed1.png",
            namee: "Diksha Sharma",
            country: "UK Aspirant",
            text: "My name's Diksha Sharma and I cleared my OET in my first attempt itself. The teachers here at Languagr Hub are the biggest reason for that and without their continued support I would not have cleared OET especially in reading and speaking."
        },
        {
            img: "/assets/home/feed2.png",
            namee: "Riya",
            country: "Australia Aspirant",
            text: "I'm Riya from Bangalore. I cleared my OET from Language Hub. The classes here are wonderfully well- designed, friendly teachers and great learning environment. I totally loved my experience, and would recommend Language Hub to all."
        },
        {
            img: "/assets/home/feed6.png",
            namee: "Bhavya Rai",
            country: "Canada Aspirant",
            text: "I'm from a small town in Karnataka and my schooling was in Kannada medium. So I never thought that I will get pasing OET required scores in my first attempt itself. Thank you Language Hub for all the support and training you provided."
        },
        {
            img: "/assets/home/feed3.png",
            namee: "Aleena",
            country: "USA Aspirant",
            text: "I personally feel that Language Hub is one of the best choices for OET training. The most interesting part about this institution is the quality classes they provide and also the dedicated mentors who are very willing to clarify our doubts at any time."
        },
        {
            img: "/assets/home/feed5.png",
            namee: "Priya",
            country: "USA Aspirant",
            text: "Being a Malayalee nurse getting a good band score in OET was a challenge for me. If I don't get the required scores I will not be able to work abroad. But learning from Language Hub helped me get that score at the the first try itself."
        },
    ]
}
import React from "react";
import Question from "../Cards/Question";
import FadeUpDown from "../Animation/FadeUpDown";

const FAQ = ({ items }) => {
  return (
    <section>
      <img
        className="lg:block hidden lg:absolute px-[10%] py-[5%]"
        src="/assets/home/faq.png"
        alt="why"
      />
      <div className=" w-full py-16 lg:px-[20%] md:px-[15%] px-10">
        <FadeUpDown yOffset={15} delay={0.6}>
        <h1 className="lg:text-5xl md:text-4xl text-3xl font-gilroy text-primary text-center pb-10">
          Frequently Asked Questions
        </h1>
        </FadeUpDown>
        {items.map((item) => (
          <FadeUpDown yOffset={15} delay={0.6}>
          <Question {...item} />
          </FadeUpDown>
        ))}
      </div>
    </section>
  );
};

export default FAQ;

FAQ.defaultProps = {
  items: [
    {
      v: "1",
      ques: "What is the duration of OET?",
      ans: "Aspirants looking to appear for the exam should be aware that the test is divided into four sections, i.e. Listening (40 mins), Reading (60 Mins), Writing (45 Mins) and Speaking (20 Mins). Candidates are required to complete each subtest within the stipulated section time.",
    },
    {
      v: "2",
      ques: "What is the price of OET?",
      ans: "Healthcare professionals looking to appear for their OET are required to pay a registration fee of $587 AUD for the exam. All payments towards OET are required to be made through the online method, using a valid Visa or Master Card.",
    },
    {
      v: "3",
      ques: "How is OET different from other tests?",
      ans: "The OET is designed for 12 specific healthcare professions and candidates belonging to a similar discipline are advised to take the exam. As part of the OET Exam Pattern, the exam requires candidates to solve healthcare scenarios during the English and Communication Skills tests.",
    },
    {
      v: "4",
      ques: "Which countries officially recognize OET?",
      ans: "OET is recognized as proof of English Language proficiency by health regulators across Australia, Canada, Ireland, Maldives, Malta, Namibia, New Zealand, Philippines, Qatar, Singapore, Spain, Ukraine, United Kingdom, United Arab Emirates and the United States of America.",
    },
    {
      v: "5",
      ques: "What is the validity of OET exam?",
      ans: "There is no blanket validity for OET. The exam is accepted in multiple countries and each country and the medical board pertaining to the country has the discretionary power to decide the validity for the said OET exam.",
    },
    {
      v: "6",
      ques: "How do I recieve my OET results?",
      ans: "Candidates should know that their OET results are published on the official website 17 days after the day of the test. Healthcare professionals can access their results by visiting their profiles on the official website.",
    },
    {
      v: "7",
      ques: "What items are allowed in the test venue?",
      ans: "Candidates should be careful to check the exhaustive list of items that are allowed and prohibited on the premises of the examination hall on the day of the exam. It is important to go through the list to avoid any last-minute hassle. Electronic items such as mobile phones, cameras, tablets, and music players are strictly prohibited from the examination.",
    },
    {
      v: "8",
      ques: "What are OET cancellation charges?",
      ans: "Candidates looking to appear for their OET exam can defer up to the last moment. However, candidates appearing to defer or change the date of the examination are required to pay a fee for the same. Candidates can check the official website to know the updated charges regarding the same",
    },
  ],
};

import React from 'react'

const LangHub = ({img, title, text}) => {
  return (
    <section>
        <div className='bg-white shadow-xl rounded-xl p-7'>
            <img className='h-3/4 lg:h-max' src={img} alt='icon' />
            <h2 className='text-2xl font-gill font-black py-5'>{title}</h2>
            <p className='text-grey font-gill font-medium'>{text}</p>
        </div>
    </section>
  )
}

export default LangHub
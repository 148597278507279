import React from 'react'

const OETCard = ({img, text}) => {
  return (
    <div className='bg-white shadow-xl  border-2 border-grey/[0.10] rounded-xl w-full p-5'>
        <img className='mx-auto' src={img} alt=' oet icon' />
        <h2 className='font-gill text-xl font-grey text-center py-3 font-medium'>{text}</h2>
    </div>
  )
}

export default OETCard
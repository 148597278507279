import React from 'react'
import FadeUpDown from '../Animation/FadeUpDown'

const Choose = () => {
  return (
    <section id='about-us'>
        <div className='grid lg:grid-cols-2 lg:px-20 px-10 place-content-center lg:pt-20 pt-10'>
            <div>
              <FadeUpDown xOffset={-15} delay={0.4}>
                <img src='/assets/home/choose.png' alt='choose' />
              </FadeUpDown>
            </div>
            <div className='place-content-center grid lg:pr-10 gap-4 '>
              <FadeUpDown xOffset={15} delay={0.4}>
                <h1 className='text-5xl font-gilroy text-primary pt-7'>Choose our Convivence</h1>
                <p className='text-md font-semibold font-gil font-grey grid text-justify'><span>Language Hub is a premier training institute that specializes in providing comprehensive education to students seeking to improve their English language proficiency and perform well in examinations such as OET and IELTS.</span>
                <span className='py-3'>Our dedicated team of highly experienced and qualified faculty have successfully guided over 10,000 students in the past 8 years to crack OET and IELTS exams and achieve their dream jobs abroad. Our individualized approach to mentoring and use of the latest pedagogy strategies in OET and IELTS, has helped our students develop the confidence and skills needed to excel in these exams.</span>
                <span>At Language Hub, we understand that learning a language in a professional setting can be challenging, that’s why we strive to make the process easier and more enjoyable by providing personalized guidance and support through our mentor-mentee relationship.</span></p>
              </FadeUpDown>
            </div>
        </div>
    </section>
  )
}

export default Choose
import { BrowserRouter } from "react-router-dom";
import Footer from "./Layout/Footer/Footer";
import Navbar from "./Layout/Navbar/Navbar";
// import Home from "./Pages/Home";
import { CTA, Choose, Landing, OET, Why, Feedback, FAQ } from "./Components";

function App() {
  return (
    <div className="relative">
      <BrowserRouter>
        <Navbar />
        <Landing />
        <Choose />
        <Why />
        <OET />
        <Feedback />
        <FAQ />
        <CTA />
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;

import React from 'react'
import LangHub from '../Cards/LangHub'
import FadeUpDown from '../Animation/FadeUpDown'


const Why = ({items}) => {
  return (
    <section id='why-choose-us'>
        <div className="lg:relative lg:mt-20 pb-20 lg:pb-0 my-10 bg-green lg:bg-none">
          <div className='lg:absolute w-full px-[12%]'>
              <div className='py-14'>
                <FadeUpDown yOffset={15} delay={0.8}>
                <h1 className='lg:text-5xl md:text-4xl text-3xl font-gilroy text-primary text-center'>Why choose Language Hub?</h1>
                </FadeUpDown>
              </div>
              <div className='grid lg:grid-cols-3 gap-14'>
                  {items.map((item) => (
                    <div>
                      <FadeUpDown yOffset={15} delay={0.8}>
                    <LangHub {...item} />
                  </FadeUpDown>
                </div>
                  ))}
              </div>
          </div>
          <img className='lg:block hidden' src='/assets/home/why.png' alt='why' />
        </div>
    </section>
  )
}

export default Why

Why.defaultProps = {
  items: [
    {
      img: "/assets/home/icon1.png",
      title: "8+ Years of Experience",
      text: "With our extensive expertise and successful track record of training over 10000 students, rest assured that you will be in the capable hands of industry leaders who will provide you with a superior training experience that will empower you to achieve your maximum potential."
    },
    {
      img: "/assets/home/icon3.png",
      title: "Expert Faculty",
      text: "Our expert TESOL certified instructors possess specialized knowledge and experience in each of the four OET modules and will make learning both easy and enjoyable. Our trainers will provide you with the necessary support and resources to enhance your learning and help you reach your full potential."
    },
    {
      img: "/assets/home/icon4.png",
      title: "Excellence Guaranteed",
      text: "We are unwavering in our commitment to excellence and strive to achieve this through a rigorous and resilient approach, infused with passion and dedication. Our promise to you is to always provide value and outstanding service, and this is reflected in the quality of our teaching and learning."
    },
    {
      img: "/assets/home/icon5.png",
      title: "One-on-One Coaching",
      text: "We provide personalized attention to each student and closely monitor your progress through regular assignment follow-ups and challenge you to exceed your own expectations."
    },
    {
      img: "/assets/home/icon6.png",
      title: "Online Classes",
      text: "Customised online classes, made available 24/7 to accommodate each student based on their convenience. Learn from the comfort of your home even while you are working elsewhere."
    },
    {
      img: "/assets/home/icon2.png",
      title: "Best Pricing",
      text: "One of our standout features is the personalized coaching and expert guidance provided to each student at highly competitive prices. We offer flexible budget friendly payment plans."
    },
  ]
}
import React from "react";
import { FaFacebookF } from "react-icons/fa";
import { AiFillInstagram, AiFillLinkedin } from "react-icons/ai";
import { BsYoutube } from "react-icons/bs";
import { HashLink as Link } from "react-router-hash-link";

const Footer = ({ items }) => {
  return (
    <section className="bg-dark md:pt-5 pt-2">
      <div className="grid md:grid-cols-3 mt-10 lg:px-32 px-5 pb-5">
        <div className="">
          <Link to="#" smooth>
            <img
              className="w-[70%] mx-auto cursor-pointer"
              src="/assets/logo.png"
              alt="logo"
            />
          </Link>
          <p className="text-white my-5 leading-tight mx-auto text-center font-medium text-lg font-gil md:text-justify">
            Language Hub is a premier training institute that specializes in
            providing comprehensive education to students seeking to improve
            their English language proficiency and perform well in examinations
            such as OET and IELTS.
          </p>
        </div>
        <div className="md:ml-[40%] md:block hidden">
          <h1 className="text-xl font-medium mt-20 text-white font-gil">
            Quicklinks
          </h1>
          {items.map((item) => (
            <Link to={item.link} smooth>
              <li className="list-none text-white relative group py-1 cursor-pointer">
                {item.text}
                <span className="absolute bottom-1 left-0 w-0 h-0.5 bg-secondary transition-all group-hover:w-[20%]"></span>
              </li>
            </Link>
          ))}
        </div>
        <div className="md:ml-[25%] md:text-left text-center font-gil">
          <h1 className="md:mt-20 mt-10 text-white font-medium text-xl">
            Contact Us
          </h1>
          <a href="tel:+91 7736629777">
            <p className="text-white leading-loose">+91 77366 29777</p>
          </a>
          <p className="text-white leading-loose">
            1c (s) Abad Oriental Gardens, Deshabamani Rd, Kochi 682026
          </p>
        </div>

        <div className="block md:hidden text-center mt-10">
          <h1 className="text-xl font-medium mt-5 text-white">Quicklinks</h1>
          {items.map((item) => (
            <Link to={item.link} smooth>
              <li className="list-none text-white relative group py-1 cursor-pointer">
                {item.text}
                <span className="absolute bottom-1 left-0 w-0 h-0.5 bg-secondary transition-all group-hover:w-[20%]"></span>
              </li>
            </Link>
          ))}
        </div>
      </div>

      <div className="bg-secondary/[0.70] md:flex justify-around py-1 items-center">
        <div>
          <h1 className="md:py-2 py-5 text-sm font-bold text-center md:text-justify">
            All Right Reserved | Language Hub - The Language Experts
          </h1>
        </div>
        <div className="flex justify-center md:py-0 pb-3 items-center">
          <a href="https://www.facebook.com/languagehubthelanguageexperts">
            <FaFacebookF className="w-8 h-8 text-secondary bg-white rounded-full p-1.5 mx-2 hover:bg-secondary hover:text-white border-2 border-white cursor-pointer" />
          </a>

          <a href="https://instagram.com/languagehubthelanguageexperts?igshid=NGExMmI2YTkyZg==">
            <AiFillInstagram className="w-8 h-8 text-secondary bg-white rounded-full p-1.5 mx-2 hover:bg-secondary hover:text-white border-2 border-white cursor-pointer" />
          </a>

          <a href="https://www.youtube.com/@languagehubthelanguageexperts/">
            <BsYoutube className="w-8 h-8 text-secondary bg-white rounded-full p-1.5 mx-2 hover:bg-secondary hover:text-white border-2 border-white cursor-pointer" />
          </a>

          <a href="https://www.linkedin.com/in/language-hub-the-language-experts-9b7263284/">
            <AiFillLinkedin className="w-8 h-8 text-secondary bg-white rounded-full p-1.5 mx-2 hover:bg-secondary hover:text-white border-2 border-white cursor-pointer" />
          </a>
        </div>
      </div>
    </section>
  );
};

Footer.defaultProps = {
  items: [
    {
      text: "Home",
      link: "#",
    },
    {
      text: "About Us",
      link: "#about-us",
    },
    {
      text: "Why Choose Us",
      link: "#why-choose-us",
    },
    {
      text: "Why Choose OET",
      link: "#why-choose-oet",
    },
  ],
};

export default Footer;
